<template>
  <CCard>
    <CCardHeader><h4 class="subhead-modal">History Detail</h4></CCardHeader>
    <CCardBody>
      <CDataTable
        :items="historyDetailData"
        :fields="fields"
        :items-per-page="5"
        :loading="isLoading"
        items-per-page-select
        hover
        sorter
        pagination
      >
      </CDataTable>
    </CCardBody>
    <CCardFooter>
        <CCol md="12">
            <CButton color="danger" @click="Back()">Back</CButton>
        </CCol>
    </CCardFooter>
  </CCard>
</template>
<script>
import axios from "axios";
import store from "../../store";

const fields = [
  { key: "RowNo", label: "#" },
  { key: "CreatedNo", label: "Created No." },
  { key: "ReleaseLevel", label: "Release level" },
  { key: "ReleaseAction", label: "Release action" },
  { key: "ReleaseActionEmployee", label: "Release action employee" },
  { key: "RejectComment", label: "Reject comment" },
  { key: "StringActionDate", label: "Action date" }
];
export default {
  name: "HistoryDetail",
  data() {
    return {
      fields,
      historyDetailData: [],
      createdNo: "",
      isLoading: true
    };
  },
  methods: {
    GetHistoryDetail() {
      axios
        .get(store.getters.URL + "/api/History/gethistorydetail", {
          params: { createdNo: this.createdNo }
        })
        .then(res => {
          if (res.data.StatusCode === 200) {
            this.historyDetailData = res.data.Data;
            this.isLoading = false;
          } else {
            this.$_toast_Noti("error", "error", res.data.Message);
            this.isLoading = false;
          }
        });
    },
    Back(){
        this.$router.push({ path: "/history/History"});
    }
  },
  created() {
    this.createdNo = this.$router.history.current.query.CreatedNo;
    //console.log("Test", createdNo);
  },
  mounted(){
      this.GetHistoryDetail()
  }
};
</script>
